<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo_podpis_Color.svg" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">SIGN IN TO CONTINUE.</p>
                <form
                    [formGroup]="valForm"
                    class="form-validate mb-3"
                    role="form"
                    name="loginForm"
                    novalidate=""
                    (submit)="submitForm($event, valForm.value)"
                >
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input
                                class="form-control border-right-0"
                                id="exampleInputusername1"
                                type="username"
                                name="username"
                                placeholder="Enter username"
                                autocomplete="off"
                                formControlName="username"
                                required=""
                            />
                            <div class="input-group-append">
                                <span
                                    class="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"
                                ></span>
                            </div>
                        </div>
                        <div
                            class="text-danger"
                            *ngIf="
                                valForm.controls['username'].hasError('required') &&
                                (valForm.controls['username'].dirty || valForm.controls['username'].touched)
                            "
                        >
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input
                                class="form-control border-right-0"
                                id="exampleInputPassword1"
                                type="password"
                                name="password"
                                placeholder="Password"
                                formControlName="password"
                                required=""
                            />
                            <div class="input-group-append">
                                <span
                                    class="input-group-text fa fa-lock text-muted bg-transparent border-left-0"
                                ></span>
                            </div>
                        </div>
                        <div
                            class="text-danger"
                            *ngIf="
                                valForm.controls['password'].hasError('required') &&
                                (valForm.controls['password'].dirty || valForm.controls['password'].touched)
                            "
                        >
                            This field is required
                        </div>
                    </div>
                    <div class="clearfix">
                        <div class="checkbox c-checkbox float-left mt0">
                            <label>
                                <input type="checkbox" value="" name="account_remember" />
                                <span class="fa fa-check"></span>Remember Me</label
                            >
                        </div>
                        <!-- <div class="float-right">
                            <a class="text-muted" [routerLink]="'/recover'">Forgot your password?</a>
                        </div> -->
                    </div>
                    <button class="btn btn-block btn-primary mt-3" type="submit">Login</button>
                </form>
                <!-- <div class="alert alert-danger text-center" ></div> -->
                <!-- <p class="pt-3 text-center">Need to Signup?</p>
                <a class="btn btn-block btn-secondary" [routerLink]="'/register'">Register Now</a> -->
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.app.name }} v{{ version }}</span>
            <br />
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
