import { CoolingSystemComponent } from './ski-jump-settings/cooling-system/cooling-system.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error404Component } from './pages/error404/error404.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { LockComponent } from './pages/lock/lock.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { RegisterComponent } from './pages/register/register.component';
import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth.guard';

export const routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
            {
                path: 'ski-jump-settings',
                loadChildren: () =>
                    import('./ski-jump-settings/ski-jump-settings.module').then((m) => m.SkiJumpSettingsModule),
            },
            {
                path: 'led-settings',
                loadChildren: () => import('./led-settings/led-settings.module').then((m) => m.LedSettingsModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
            },
        ],
    },
    // { path: 'cooling-system', component: CoolingSystemComponent },
    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    // { path: 'register', component: RegisterComponent },
    // { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
    // Not found
    { path: '**', redirectTo: 'home' },
];
