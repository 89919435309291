import { BaseState } from './../../../shared/base/base.state';
import { TsLinkUser } from './../../../shared/model/ts-link-user.model';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { StorageService } from './../../../shared/services/storage.service';
import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit {
    user: TsLinkUser;
    constructor(
        public userblockService: UserblockService,
        private storage: StorageService,
        private router: Router,
        private store: Store,
    ) {
        // this.user = {
        //     picture: 'assets/img/user/01.jpg'
        // };
    }

    ngOnInit() {
        this.user = this.store.selectSnapshot(BaseState.GetUserInfo);
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    public signOut(): void {
        this.storage.clearUserData();
        this.router.navigate(['/login']);
    }
}
