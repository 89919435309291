import { Hill } from './../model/hill.model';
import { TsLinkUser } from './../model/ts-link-user.model';
import { UserInfo } from './../model/user-info.model';
import { Injectable } from '@angular/core';
import { BaseStateModel } from './base-state.model';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { BaseStateActions } from './base.actions';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    token: undefined,
    userInfo: undefined,
    userHills: undefined,
    selectedHill: undefined,
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);

    constructor() {}

    @Selector([BASESTATE_TOKEN])
    public static getToken(state: BaseStateModel): string {
        return state.token;
    }

    @Selector([BASESTATE_TOKEN])
    public static GetUserInfo(state: BaseStateModel): TsLinkUser {
        return state.userInfo;
    }

    @Selector([BASESTATE_TOKEN])
    public static getSelectedHill(state: BaseStateModel): Hill {
        return state.selectedHill;
    }

    @Selector([BASESTATE_TOKEN])
    public static getUserHills(state: BaseStateModel): Hill[] {
        return state.userHills;
    }

    @Action(BaseStateActions.ClearBaseState)
    public ClearState(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.ClearBaseState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(BaseStateActions.Common.SetToken)
    public SetToken(ctx: StateContext<BaseStateModel>, { token }: BaseStateActions.Common.SetToken) {
        ctx.patchState({ token: token });
    }

    @Action(BaseStateActions.Common.SetUserInfo)
    public SetUserInfo(ctx: StateContext<BaseStateModel>, { userInfo }: BaseStateActions.Common.SetUserInfo) {
        ctx.patchState({ userInfo: userInfo });
    }

    @Action(BaseStateActions.Common.SetUserHills)
    public SetUserHills(ctx: StateContext<BaseStateModel>, { hills }: BaseStateActions.Common.SetUserHills) {
        if (hills.length > 0) {
            ctx.patchState({ userHills: hills, selectedHill: hills[0] });
        }
    }

    @Action(BaseStateActions.Common.SetSelectedHill)
    public SetSelectedHill(ctx: StateContext<BaseStateModel>, { hill }: BaseStateActions.Common.SetSelectedHill) {
        ctx.patchState({ selectedHill: hill });
    }
}
