import { HttpHeaders } from '@angular/common/http';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs/Rx';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public login(username: string, password: string): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                authorization: `Basic ${btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };

        return this.httpClient.get(`${this.helper.getApiUrl()}/token`, options);
    }
}
