import { CoolingSystemComponent } from './ski-jump-settings/cooling-system/cooling-system.component';
import { AuthGuard } from './auth.guard';
import { PagesModule } from './pages/pages.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';

import { menu } from './menu';
import { routes } from './routes';

@NgModule({
    imports: [SharedModule, RouterModule.forRoot(routes), PagesModule],
    declarations: [],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        menuService.addMenu(menu);
    }
}
