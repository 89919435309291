import { Hill } from './../model/hill.model';
import { TsLinkUser } from './../model/ts-link-user.model';
import { UserInfo } from './../model/user-info.model';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public getUserData(username: string): Observable<TsLinkUser> {
        return this.httpClient.get(`${this.helper.getApiUrl()}/users/${username}`);
    }

    public getUserHills(username: string): Observable<Hill[]> {
        return this.httpClient.get(`${this.helper.getApiUrl()}/users/${username}/hills`);
    }

    // public getUserData(username: string): Observable<any> {
    //     return this.httpClient.get(`${this.helper.getApiUrl()}/users/${username}`).pipe(map((el) => el.tslinkUser));
    // }
}
