import { ModuleEnita } from './module-enita.model';
import { ModuleLights } from './module-lights.model';
import { Modules } from './../enums/modules.enum';
import { Serializable } from './deserialize.service';
export class TsLinkUser implements Serializable<TsLinkUser> {
    username: string;
    password: string;
    role: string;
    modules: Modules[];
    moduleLights: ModuleLights;
    moduleEntia: ModuleEnita;
    hillsIDs: number[];
    // password: string;

    // constructor(obj?: any) {
    //     this.name = obj && obj.name || null;
    //     this.doctorBpi = obj && obj.doctorBpi || null;
    //     this.institutions = obj && obj.institutions || null;
    // }

    deserialize(input) {
        // debugger;
        this.username = input.username;
        this.role = input.role;
        this.modules = input.modules;
        this.password = input.password;
        this.moduleEntia = input.moduleEntia;
        this.hillsIDs = input.hillsIDs;
        return this;
    }
}
