import { Injectable } from '@angular/core';
// import { User } from '../model/user-info.model';

@Injectable()
export class StorageService {
    constructor() {}

    public saveToken(token: string): void {
        localStorage.setItem('tslink-credentialsData', JSON.stringify(token));
    }

    public getToken(): any {
        return JSON.parse(localStorage.getItem('tslink-credentialsData'));
    }

    public clearToken(): any {
        return localStorage.removeItem('tslink-credentialsData');
    }

    // //setUserData
    // public setUserData(con: User): void {
    //     // debugger;
    //     this.set('tslink-userData', con);
    // }

    // public getUserData(): User {
    //     // debugger;
    //     return this.get('tslink-userData');
    // }

    public clearUserData(): void {
        localStorage.removeItem('tslink-userData');
    }

    //helpers
    private set(name: string, data) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    private get(name: string): any {
        return JSON.parse(localStorage.getItem(name));
    }
}
