import { Roles } from './../../shared/enums/roles.enum';
import { Modules } from './../../shared/enums/modules.enum';
import { TsLinkUser } from './../../shared/model/ts-link-user.model';
import { UserInfo } from './../../shared/model/user-info.model';
import { BaseState } from './../../shared/base/base.state';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {
    private menuItems: Array<any>;

    constructor(private store: Store) {
        this.menuItems = [];
    }

    addMenu(
        items: Array<{
            text: string;
            heading?: boolean;
            link?: string; // internal route links
            elink?: string; // used only for external links
            target?: string; // anchor target="_blank|_self|_parent|_top|framename"
            icon?: string;
            alert?: string;
            submenu?: Array<any>;
            roles?: string[];
            module?: Modules;
        }>,
    ) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        const user: TsLinkUser = this.store.selectSnapshot(BaseState.GetUserInfo);

        return this.menuItems.filter((item) => {
            if (user?.role == Roles.TSLINK_ADMIN) {
                return true;
            }
            if (item?.module && !user?.modules.some((el) => el == item?.module)) {
                return false;
            }
            if (!item?.roles?.some((el) => el == user?.role)) {
                return false;
            }

            return true;
        });
        // return this.menuItems.filter((item) => {
        //     return item.roles.some((el) => el == user?.tslinkUser?.role);
        // });
        // return this.menuItems;
    }
}
