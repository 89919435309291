<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <span class="user-block-role">{{ user?.username }}</span>
            <!-- <img class="img-thumbnail rounded-circle" [src]="user.picture" alt="Avatar" /> -->
            <!-- <div class="circle bg-success circle-lg"></div> -->
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <!-- <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }} Tina</span> -->
        <span class="user-block-role">{{ user?.role }}</span>
        <button _ngcontent-c9="" class="btn btn-labeled btn-danger mb-2" type="button" (click)="signOut()">
            <span _ngcontent-c9="" class="btn-label"> <i _ngcontent-c9="" class="icon-logout"></i> </span>Sign out
        </button>
    </div>
</div>
