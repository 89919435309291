import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public version: string = environment.VERSION;
    constructor(public settings: SettingsService) {}

    ngOnInit() {}
}
