import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor() {}

    public getApiUrl(): string {
        return environment.api.protocol + environment.api.rootUrl + environment.api.port + environment.api.url;
    }
}
