import { Modules } from './../shared/enums/modules.enum';
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home',
    roles: ['hill-operator', 'hill-owner'],
};
const Settings = {
    text: 'Ski Jump settings',
    link: '/ski-jump-settings',
    icon: 'icon-settings',
    module: Modules.ENTIA,
    roles: ['hill-operator', 'hill-owner'],
};

const LedSettings = {
    text: 'Led',
    link: '/led-settings',
    icon: 'icon-bulb',
    module: Modules.LIGHTS,
    roles: ['hill-operator', 'hill-owner'],
};

const CoolingSettings = {
    text: 'Cooling system',
    link: '/ski-jump-settings/cooling-system',
    icon: 'icon-speedometer',
    // icon: 'ts-icon-cooling',
    module: Modules.ENTIA,
    roles: ['hill-operator', 'hill-owner'],
};
// <div class="ts-icon-cooling"></div>
const Admin = {
    text: 'Admin',
    link: '/admin',
    icon: 'icon-diamond', //https://simplelineicons.github.io/
    submenu: [
        {
            text: 'Users',
            link: '/admin/users',
            icon: 'icon-people',
        },
        {
            text: 'Hills',
            link: '/admin/hills',
            icon: 'icon-flag',
        },
    ],
    roles: ['tslink-admin'],
};

const headingMain = {
    text: 'Main Navigation',
    heading: true,
    roles: ['hill-operator', 'hill-owner'],
};

export const menu = [headingMain, Home, Settings, CoolingSettings, LedSettings, Admin];
