import { BaseState } from '../base/base.state';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs/Rx';
import { HelperService } from './helper.service';
import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private injector: Injector, private helper: HelperService) {}

    private router = this.injector.get(Router);

    private isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<
        HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
    > {
        // private store: Store,
        const storage = this.injector.get(Store);

        const token = storage.selectSnapshot(BaseState.getToken);

        // const storage = this.injector.get(StorageService);

        // const token = storage.getToken();

        return next.handle(this.addTokenToRequest(request, token, '')).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    // debugger;
                    // if (request.url === this.helper.getAuthUrl()) {
                    //     return throwError(err);
                    // }

                    switch ((<HttpErrorResponse>err).status) {
                        case 401:
                            //TODO ni najlepsa resitev
                            if (
                                _.get(<HttpErrorResponse>err, 'error.error_description', '').indexOf(
                                    'Invalid refresh token',
                                ) !== -1
                            ) {
                                //storage.clearToken();
                                // debugger;
                                this.router.navigate(['/login']);
                                // return <any>authService.logout();
                            }
                        // return this.handle401Error(request, next);
                        case 400:
                            // debugger;
                            // storage.clearToken();
                            // router.navigate(['/login']);
                            return throwError(err);
                        case 403:
                            return throwError(err);

                        default:
                            // storage.clearToken();
                            // router.navigate(['/login']);
                            return throwError(err);
                        // case 400:
                        //     return <any>authService.logout();
                    }
                } else {
                    return throwError(err);
                }
            }),
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string, XSRFToken: string): HttpRequest<any> {
        if (request.url.includes('/token') || request.url.includes('/assets')) {
            return request;
        }

        // let useToken: boolean = true;
        // debugger;

        // let isPublic: boolean = _.get(this.router, 'url', '').indexOf('public') !== -1;
        // if (request.body && _.isString(request.body)) {
        //     if (_.get(request, 'body', '').indexOf('refresh_token') !== -1) {
        //         useToken = false;
        //     } else if (_.get(request, 'body', '').indexOf('username') !== -1) {
        //         useToken = false;
        //     } else if (request.url.indexOf('webdriver') !== -1) {
        //         return request.clone();
        //     }
        // } else if (isPublic) {
        //     useToken = false;
        // }

        if (!token) {
            this.router.navigate(['/login']);
        }
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}

// private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
//     // debugger;
//     const httpClientMF = this.injector.get(HttpClientService);
//     const authService = this.injector.get(AuthenticationService);

//     if (!this.isRefreshingToken) {
//         this.isRefreshingToken = true;

//         // Reset here so that the following requests wait until the token
//         // comes back from the refreshToken call.
//         this.tokenSubject.next(null);

//         return httpClientMF.refreshToken().pipe(
//             switchMap((token: any) => {
//                 debugger;
//                 const accessToken = _.get(token, 'access_token', undefined);
//                 if (accessToken) {
//                     this.tokenSubject.next(accessToken);
//                     // localStorage.setItem('currentUser', JSON.stringify(user));
//                     return next.handle(this.addTokenToRequest(request, accessToken, ''));
//                 }

//                 return <any>authService.logout();
//             }),
//             catchError((err) => {
//                 // debugger;
//                 return <any>authService.logout();
//             }),
//             finalize(() => {
//                 this.isRefreshingToken = false;
//             }),
//         );
//     } else {
//         this.isRefreshingToken = false;

//         return this.tokenSubject.pipe(
//             filter((token) => token != null),
//             take(1),
//             switchMap((token) => {
//                 return next.handle(this.addTokenToRequest(request, token, ''));
//             }),
//         );
//     }
// }
// }
